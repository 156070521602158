<template>
    <div class="row no-gutters" v-if="showBar">
        <div class="col bg-primary px-5 py-1 text-white">
            <b>{{ bidder.fname }}</b>, you are up to bid <b>{{ bidder.round_name }}</b>.
            <bid-button v-if="memberBidButton" v-bind="memberBidButton" small class="ml-2"/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BidButton } from '@/components'

export default {
  name: 'user-bidder',
  components: {
    BidButton
  },
  props: {},
  data () {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters({
      active: 'bidder/active',
      memberBidder: 'bidder/member',
      rounds: 'round/activeRounds',
      member: 'member/member'
    }),
    bidder () {
      // Only use Bidders with rounds in the current facility/area
      // let bidders = _.filter(this.memberBidder(this.member.id), b => Object.keys(this.rounds).includes('' + b.round_id) && b.up_to_bid);
      const bidders = _.filter(this.memberBidder(this.member.id), b => _.find(this.rounds, { id: b.round_id }) && b.up_to_bid)
      return bidders.length ? bidders[0] : null
    },
    memberBidButton () {
      // Make sure the logged in controller has a bidder and is in this facility/area
      if (!this.bidder) { return false }

      // Bidder valid so return the first one (API sorts by round number)
      return {
        round: this.round,
        bidder: this.bidder
      }
    },
    round () {
      return _.find(this.rounds, { id: this.bidder.round_id })
    },
    showBar () {
      return !this.active && this.bidder && this.bidder.up_to_bid && this.round
    }
  }
}
</script>

<style scoped>

</style>
