<template>
    <div>
        <h6 class="text-danger text-center">
            <i class="fas fa-exclamation-triangle" />
                FACILITY BIDDING CLOSED
            <i class="fas fa-exclamation-triangle text-danger" />

            <el-tooltip>
              <div class="text-left mt-2" slot="content">
                This facility has set start and end times for bidding for each day that are displayed below. Bids can
                only be entered by BUEs during open hours. Admins, Reps, and Bidders can all enter bids and operate as
                usual. Notifications will continue to be sent when bids are entered.
                <div class="mt-2">
                    <b>OPEN</b>: {{ facility.today.open | localDisplay('HH:mm', true) }}<br>
                    <b>CLOSE</b>: {{ facility.today.close | localDisplay('HH:mm', true) }}
                </div>
              </div>

              <n-button round small type="info" class="bid-button small ml-2">
                <i class="fas fa-info-circle mr-2"/> More Information
              </n-button>
            </el-tooltip>
        </h6>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'bidding-closed',
  components: {},
  props: {},
  data () {
    return {
    }
  },
  methods: {},
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      areas: 'area/areas',
      is: 'auth/is'
    })
  }
}
</script>
