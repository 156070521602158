<template>
    <div class="navbar-wrapper text-white sub-navbar" v-if="areas.length > 1 && areas.length <= 8">
          <div class="row flex-nowrap no-gutters">
              <div class="col">
                  <router-link v-for="area in areas"
                               :key="area.id"
                               :to="{ name: 'Area Home', params: { facility: facility.id, area: area.slug } }"
                               class="area-link text-decoration-none text-gray mr-2"
                               :class="{ 'area-link-selected': areaIs(area.slug) }">
                      {{ area.name }}
                  </router-link>
              </div>
          </div>
    </div>
</template>
<script>
    import {mapGetters} from 'vuex';

    export default {
        components: {},
        name: 'subheader',
        props: {},
        data() {
            return {}
        },
        computed: {
            ...mapGetters({
                facility: 'facility/facility',
                areas: 'area/areas',
                areaIs: 'area/is',
            }),
        },
        methods: {},
        watch: {}
    }

</script>
<style></style>
