<template>
    <el-popover placement="bottom"
                title="Bid Summary"
                width="400"
                trigger="manual"
                :value="showBidCart && bidMode">
        <div class="cart">
            <ul class="list-unstyled mb-0">
    <!--                <li class="cart-leave">-->
    <!--                    <h6>Leave Bidding For Round {{ round.leave_round }}</h6>-->
    <!--                    <b>Hours Bid/Left:</b> {{ hoursBid }}/{{ hoursAvailable - hoursBid }}-->
    <!--                    <b>Days Bid/Left:</b> {{ daysBid }}/{{ round.allowed_days - daysBid }}-->
    <!--                    <b>Holiday Hours/Days:</b> {{ holidayHoursBid }}/{{ holidayDaysBid }}-->
    <!--                </li>-->
                <li class="cart-week"
                    v-for="(week, weekIndex) in bidWeeks"
                    :key="'week-' + weekIndex">
                    <div class="row">
                        <div class="col mt-2">
                            <span class="cart-week-title font-weight-bold" v-if="round.require_consecutive_days">Week {{ weekIndex + 1 }}</span>
                            <div class="row no-gutters cart-dates">
                                <div class="col">
                                    <ul class="list-inline" v-if="week.bids.length">
                                        <li v-for="(bid, bidIndex) in week.bids"
                                            class="list-inline-item"
                                            :key="'bid-' + bidIndex">
                                            <i class="comma" v-if="bidIndex !== 0">,</i>
                                            {{ bid.day.month + '/' + bid.day.day }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-1">
                                    <a href="#/"
                                       v-if="weekIndex !== 0"
                                       @click.prevent="$store.commit('leave/deleteBidWeek', weekIndex); $store.commit('leave/toggleBidCart', false)">
                                        <i class="fas fa-trash text-danger"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Display hack for new week -->
    <!--                    <template>&lt;!&ndash; v-if="weekIndex + 1 === bids.length && !week.active"> &ndash;&gt;-->
    <!--                        <li class="cart-head font-weight-bold">Week {{ weekIndex + 2 }}</li>-->
    <!--                        <li class="cart-dates">&nbsp;</li>-->
    <!--                    </template>-->

                </li>
            </ul>

            <!-- Add week -->
            <span class="add-week" v-if="canAddWeek">
                <a href="#/" @click.prevent="addWeek()">
                    <i class="fas fa-plus"></i> Add Week</a>
            </span>
        </div>

        <div class="row d-block mt-4">
            <div class="col">
                <p class="m-0 p-2">
                    I have reviewed the selected dates and understand that I am not entitled to any changes to these dates
                    in accordance with the Slate Book CBA, Article 24 Section 11 (f).
                </p>
                <n-button round block type="success" class="mx-0" @click.native="bid">
                    <i class="fas fa-clipboard-check fa-lg mr-2"></i><b>Submit Leave Bid</b>
                </n-button>
            </div>
        </div>

        <n-button slot="reference"
                  type="success"
                  round
                  size="sm"
                  class="bid-button small m-0 mr-2"
                  :disabled="bids.length < 1"
                  @click.native="$store.commit('leave/toggleBidCart')" >
            <i class="fas fa-shopping-cart mr-2"></i>
            Leave Bids <badge type="danger" v-if="bids.length" class="ml-2">{{ bids.length }}</badge>
        </n-button>
    </el-popover>
</template>

<script>
import { mapGetters } from 'vuex'
import { Popover } from 'element-ui'
import { Badge } from '@/components/stateless'

export default {
  name: 'leave-bid-cart',
  components: {
    [Popover.name]: Popover,
    [Badge.name]: Badge
  },
  props: {},
  data () {
    return {}
  },
  methods: {
    addWeek () {
      this.$store.commit('leave/newBidWeek')
      this.$store.commit('leave/toggleBidCart', false)
    },
    bid () {
      this.$store.dispatch('leave/prepareLeave')
        .then(() => {
          this.$store.commit('leave/toggleBidCart', false)

          if (this.is('bidaid')) { this.$router.push({ name: 'Round', params: { facility: this.facility.id, area: this.area.slug } }) } else { this.$router.push({ name: 'Area Home', params: { facility: this.facility.id, area: this.area.slug } }) }
        })
    }
  },
  computed: {
    ...mapGetters({
      is: 'auth/is',
      facility: 'facility/facility',
      area: 'area/area',
      bidWeeks: 'leave/bidWeeks',
      weeksRemaining: 'leave/weeksRemaining',
      weekBidsRemaining: 'leave/weekBidsRemaining',
      activeBidWeek: 'leave/activeBidWeek',
      bids: 'leave/bids',
      rounds: 'round/activeRounds',
      bidMode: 'bidder/bidMode',
      bidder: 'bidder/active',
      showBidCart: 'leave/showBidCart'
    }),
    canAddWeek () {
      return this.weeksRemaining > 0 && this.activeBidWeek
    },
    round () {
      return _.find(this.rounds, { id: this.bidder.round_id })
    }
  },
  watch: {
    weekBidsRemaining (n) {
      if (n === 0) {
        this.$store.commit('leave/toggleBidCart', true)
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>

<style scoped>

</style>
