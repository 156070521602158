<template>
  <navbar :show-navbar="showNavbar">
    <div class="navbar-wrapper font-weight-normal">
      <div class="navbar-toggle" :class="{toggled: $sidebar.showSidebar}">
        <navbar-toggle-button @click.native="toggleSidebar">
        </navbar-toggle-button>
      </div>
      <b v-if="screenSizeIs(['lg', 'xl'])">{{ facility.name }}</b>
      <b v-else>{{ facility.id | uppercase }}</b>

      <template v-if="showAreaDropdown">
        <i class="fas fa-fighter-jet fa-sm ml-2 mr-1"/>
        <area-dropdown v-if="showAreaDropdown" class="mx-1"/>
      </template>

      <template v-if="!showAreaDropdown && area.name && areas.length > 1">
        <i class="fas fa-fighter-jet fa-sm ml-2 mr-1"/>
        <i class="mr-2">{{ area.name }}</i>
      </template>

      <template v-if="screenSize !== 'xs'">
        <i class="fas fa-fighter-jet fa-sm ml-2 mr-1"/>
        <i>{{ $route.name }}</i>
      </template>
    </div>

    <button @click="toggleNavbar" class="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navigation"
            aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>

    <template slot="navbar-menu">
      <ul class="navbar-nav text-dark">
        <drop-down v-if="is('admin')"
                   tag="li"
                   position="right"
                   class="nav-item"
                   :title="showNavbar ? 'Facility Admin' : ''"
                   icon="fas fa-cog fa-lg mr-2">
          <router-link :to="{ name: 'Facility Admin', params: { facility: facility.id }}" tag="a" class="dropdown-item">
            <i class="fas fa-tools"></i> Configure Facility
          </router-link>
          <router-link :to="{ name: 'Admin Areas', params: { facility: facility.id }}" tag="a" class="dropdown-item">
            <i class="fas fa-layer-group"></i> Manage Areas
          </router-link>
          <router-link :to="{ name: 'Manage Members', params: { facility: facility.id }}" tag="a" class="dropdown-item">
            <i class="fas fa-users"></i> Manage Members
          </router-link>
          <router-link :to="{ name: 'Messages', params: { facility: facility.id }}" tag="a" class="dropdown-item">
            <i class="fas fa-bell"></i> Controller Notifications
          </router-link>
        </drop-down>

        <drop-down tag="li"
                   position="right"
                   class="nav-item"
                   :title="showNavbar ? 'User Profile' : ''"
                   icon="fas fa-user fa-lg mr-2">
          <router-link v-if="loggedIn"
                       :to="{ name: 'Controller Details', params: { facility: $route.params.facility, member_id: user.member_id }}"
                       class="dropdown-item">
            My Info
          </router-link>
          <a v-if="loggedIn" class="dropdown-item" href="#" @click.prevent="$auth.logout()">Logout</a>
          <a v-else href="#" @click.prevent="$auth.loginWithRedirect()" class="dropdown-item">Login</a>
          <a v-if="!loggedIn" href="https://my.natca.org/register" class="dropdown-item">Register</a>

        </drop-down>
      </ul>
    </template>
  </navbar>
</template>
<script>
import DropDown from '@/components/stateless/DropDown'
import { mapGetters } from 'vuex'
import { AreaDropdown } from '@/components'
import { Navbar, NavbarToggleButton } from '@/components/stateless'

export default {
  components: {
    AreaDropdown,
    DropDown,
    Navbar,
    NavbarToggleButton
  },
  name: 'navbar-top',
  props: {},
  data () {
    return {
      activeNotifications: false,
      showNavbar: false,
      init: ''
    }
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      areas: 'area/areas',
      area: 'area/area',
      loggedIn: 'auth/loggedIn',
      is: 'auth/is',
      user: 'auth/user'
    }),
    showAreaDropdown () {
      // Only show if more than eight areas
      return this.areas.length > 8
    },
    url () {
      return window.location.href
    }
  },
  methods: {
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown () {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown () {
      this.activeNotifications = false
    },
    toggleSidebar () {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    toggleNavbar () {
      this.showNavbar = !this.showNavbar
    },
    hideSidebar () {
      this.$sidebar.displaySidebar(false)
    }
  }
}

</script>
