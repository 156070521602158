<template>
    <div>
        <h6 class="text-danger text-center">
            <i class="fas fa-exclamation-triangle" />
                TEST MODE ACTIVE
            <i class="fas fa-exclamation-triangle text-danger" />

            <n-button round small type="info" class="bid-button small ml-2" @click.native="show = true">
                <i class="fas fa-info-circle mr-2" /> More Information
            </n-button>
        </h6>

        <el-dialog
          title="Test Mode Details"
          :visible.sync="show"
          :width="dialogWidth"
          custom-class="p-0"
          top="2vh">
            <h4 slot="header" class="title title-up">Test Mode</h4>
            <p>
                Test mode allows facilities to test the functionality of the system in a "sandbox",
                preventing notifications from being sent to controllers. Messages that are generated are
                displayed on the
                <router-link :to="{ name: 'Messages', params: { facility: facility.id }}" @click.native="show = false">Messages</router-link>
                screen for review.
            </p>

          <p>
            To disable Test Mode and configure other facility settings
            <router-link :to="{ name: 'Facility Admin' , params: { facility: facility.id }}" @click.native="show = false">CLICK HERE</router-link>.
          </p>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Modal } from '@/components/stateless'
import { Dialog } from 'element-ui'

export default {
  name: 'test-mode',
  components: {
    [Modal.name]: Modal,
    [Dialog.name]: Dialog
  },
  props: {},
  data () {
    return {
      show: false
    }
  },
  methods: {},
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      areas: 'area/areas',
      is: 'auth/is'
    })
  }
}
</script>

<style scoped>

</style>
