<template>
    <footer class="footer">
        <div class="container-fluid d-flex flex-wrap justify-content-between">
            <nav>
                <ul class="footer-menu">
                    <li>
                        <i>Last BUE Import: </i>
                        {{ facility.last_bue_update | utcDisplay('MM/DD/YYYY hh:mm:ss') }}
                    </li>
                </ul>
            </nav>
            <div>
                <i>Version 5.2.0</i>
            </div>
            <div class="copyright">
                &copy; {{ year }}, NATCA

            </div>
        </div>
    </footer>
</template>
<script>
import { mapGetters } from 'vuex'
import Intercom from '@intercom/messenger-js-sdk'
const crypto = require('crypto')

export default {
  name: 'custom-footer',
  data () {
    return {
      year: new Date().getFullYear()
    }
  },
  mounted () {
    if (this.user) {
      Intercom({
        app_id: 'x8lz0a8j',
        user_id: this.user.member_id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: this.user.full_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: this.user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        user_hash: crypto.createHmac('sha256', process.env.VUE_APP_INTERCOM_SECRET).update(this.user.member_id.toString()).digest('hex')
      })
    }
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      user: 'auth/user'
    })
  }
}

</script>
<style>

</style>
