<template>
    <div class="row no-gutters" v-if="isLeaveRound">
        <div class="col bg-lightgray px-4 py-2 text-info">
            <div class="row no-gutters">
                <div class="col">
                    <card class="card-plain m-0" card-body-classes="p-0">
                        <div class="d-inline-block ml-3">
                            <member-leave class="mr-2"
                                          :name="bidder.full_name"
                                          :member_id="bidder.member_id" />
                        </div>
                        <div class="d-inline-block">
                            <b class="mr-1">Hours:</b>
                            <i>{{ hoursBid }}/{{ hoursAllowed }}</i>
                        </div>
                        <div class="d-inline-block ml-3">
                            <b class="mr-1">Days:</b>
                            <i>{{ bids.length }}/{{ restrictions.daysAllowed }}</i>
                        </div>
                        <div class="d-inline-block ml-3" v-if="screenSize !== 'xs' && screenSize !== 'sm'">
                            <b class="mr-1">Selected:</b>
                            <i>{{ bids.map(bid => bid.$d.format('MMM D')).join(', ') }}</i>
                        </div>
                        <div class="d-inline-block float-right">
                            <leave-bid-cart />
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<style></style>
<script>
import { mapGetters } from 'vuex'
import LeaveBidCart from '@/views/leave/LeaveBidCart'
import MemberLeave from '@/components/MemberLeave'

export default {
  name: 'bidder-leave-bar',
  components: {
    MemberLeave,
    LeaveBidCart
  },
  props: {},
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      bidder: 'bidder/active',
      hoursBid: 'leave/hoursBid',
      hoursAllowed: 'leave/hoursAllowed',
      bids: 'leave/bids',
      restrictions: 'leave/restrictions',
      rounds: 'round/rounds'
    }),
    isLeaveRound () {
      const round = this.rounds[this.bidder.round_id]

      return round && round.type === 'leave'
    }
  },
  methods: {
  },
  created () {
  }
}
</script>
