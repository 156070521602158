<template>
    <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
        <notifications></notifications>
        <super-modal v-if="is('super') || mocking"></super-modal>

        <sidebar />

        <div class="main-panel">
            <navbar-top></navbar-top>
            <router-view name="header"></router-view>

            <subheader />

            <active-bidder-bar />

            <bidder-leave-bar v-if="bidMode && $route.name === 'Leave'" />

            <user-bidder />

            <div class="push-content"
                 :class="{
                    content: !$route.meta.hideContent,
                }" @click="toggleSidebar">

                <alerts></alerts>

                <test-mode v-if="facility.test_mode" />
                <bidding-closed v-if="!isFacilityOpen" />

                <zoom-center-transition :duration="200" mode="out-in">
                    <router-view></router-view>
                </zoom-center-transition>
            </div>

            <custom-footer />
        </div>
    </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'

import { mapGetters } from 'vuex'
import { Alerts, SuperModal } from '@/components'
import NavbarTop from './_Navbar.vue'
import Subheader from './_Subheader.vue'
import UserBidder from './UserBidder'
import ActiveBidderBar from './ActiveBidderBar'
import BidderLeaveBar from '@/views/Layout/BidderLeaveBar'
import TestMode from './TestMode'
import { ZoomCenterTransition } from 'vue2-transitions'
import Sidebar from './_Sidebar.vue'
import CustomFooter from './_Footer'
import BiddingClosed from '@/views/Layout/BiddingClosed'

function hasElement (className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar (className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

/**
     * Retrieve eligible bidders and update store
     * @param data
     * @param $store
     */
function fetchEligibleBidders (data, $store) {
  // Fetch the updated eligible bidders
  BidderRepository.fetchEligibleBidders(data.bidder.id, data.skipToEnd)
    .then(r => {
      const bidders = r.data

      // Loop through each eligible bidder and update the state
      _.forEach(bidders, bidder => {
        const payload = {
          bidder_id: bidder.id,
          changes: bidder
        }
        $store.commit('bidder/updateBidder', payload)
      })
    })
}

function manageActiveBidder (bidder, activate, $store) {
  // Format the bidder for the store
  const payload = {
    bidder_id: bidder.id,
    changes: bidder
  }

  // Update the store
  $store.commit('bidder/updateBidder', payload)

  // Check if activating or deactivating
  if (activate) {
    // Activate bidder and start timer
    $store.dispatch('bidder/startBidderTimer', bidder)
  } else {
    // Deactivate bidder and stop timer
    $store.dispatch('countdown/stop')
  }
}
const BidderRepository = RepositoryFactory.get('bidder')

export default {
  components: {
    BiddingClosed,
    CustomFooter,
    Alerts,
    SuperModal,
    Subheader,
    UserBidder,
    ActiveBidderBar,
    BidderLeaveBar,
    TestMode,
    ZoomCenterTransition,
    Sidebar,
    NavbarTop
  },
  data () {
    return {
      logo: require('@/assets/img/logos/natca-logo-white-bg.png'),
      channel: null
    }
  },
  methods: {
    toggleSidebar () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      isFacilityOpen: 'facility/isFacilityOpen',
      areas: 'area/areas',
      area: 'area/area',
      is: 'auth/is',
      mocking: 'auth/mocking',
      bidMode: 'bidder/bidMode',
      bidder: 'bidder/active'
    }),
    showAreaSubheader () {
      return !_.includes(['xs', 'sm'], this.screenSize)
    }
  },
  created () {},
  mounted () {
    const docClasses = document.body.classList
    const isWindows = navigator.platform.startsWith('Win')
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar('sidebar')
      initScrollbar('sidebar-wrapper')

      docClasses.add('perfect-scrollbar-on')
    } else {
      docClasses.add('perfect-scrollbar-off')
    }

    // Subscribe to Pusher for Active Bidder
    this.channel = this.$pusher.subscribe(this.facility.id)

    // Update hours
    this.channel.bind('hours', r => {
      this.$store.commit('facility/setHours', r.facility.today)
    })

    // Make sure an area is set before trying to bind
    if (this.area) {
      // Activate bidder
      this.channel.bind('bidder-' + this.area.id, (data) => {
        manageActiveBidder(data.bidder, data.shouldActivate, this.$store)
      })

      // Bind to update Eligible Bidders
      this.channel.bind('eligible-bidders-' + this.area.id, (data) => {
        fetchEligibleBidders(data, this.$store)
      })
    }
  },
  watch: {
    area (n, o) {
      // Unbind the old area
      this.channel.unbind('eligible-bidders-' + o.id)
      this.channel.unbind('bidder-' + o.id)

      // Bind to the new area
      this.channel.bind('eligible-bidders-' + n.id, (data) => {
        fetchEligibleBidders(data, this.$store)
      })

      // Activate bidder
      this.channel.bind('bidder-' + this.area.id, (data) => {
        manageActiveBidder(data.bidder, data.shouldActivate, this.$store)
      })
    },
    facility (n, o) {
      // Unsubscribe from the old facility
      if (o.id) {
        this.channel = this.$pusher.unsubscribe(o.id)
      }

      // Subscribe to the new facility
      if (n.id) {
        this.channel = this.$pusher.subscribe(n.id)
      }
    }
  }
}

</script>
<style lang="scss">
    $scaleSize: .95;
    @keyframes zoomIn95 {
        from {
            opacity: 0;
            transform: scale3d($scaleSize, $scaleSize, $scaleSize);
        }
        to {
            opacity: 1;
        }
    }

    .main-panel .zoomIn {
        animation-name: zoomIn95;
    }

    @keyframes zoomOut95 {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
            transform: scale3d($scaleSize, $scaleSize, $scaleSize);
        }
    }

    .main-panel .zoomOut {
        animation-name: zoomOut95;
    }
</style>
