<template>
    <div class="user" v-if="member">
        <div class="initials-icon">
            {{ member.init | uppercase }}
        </div>
        <div class="info">
            <a class="cursor-pointer" data-toggle="collapse" @click.stop="toggleMenu">
           <span>
             {{ member.full_name }}
             <b class="caret"></b>
          </span>
            </a>
            <div class="clearfix"></div>
            <div>
                <collapse-transition>
                    <ul class="nav" v-show="!isClosed">
                        <slot>
                            <li>
                                <router-link tag="a" :to="detailsLink">
                                    <span class="sidebar-mini-icon"><i class="fas fa-clipboard-check"></i></span>
                                    <span class="sidebar-normal">My Info</span>
                                </router-link>
                            </li>
                        </slot>
                    </ul>
                </collapse-transition>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { CollapseTransition } from 'vue2-transitions'

export default {
  components: {
    CollapseTransition
  },
  props: {},
  data () {
    return {
      isClosed: true
    }
  },
  methods: {
    toggleMenu () {
      this.isClosed = !this.isClosed
    }
  },
  computed: {
    ...mapState({
      member: 'member/member'
    }),
    detailsLink () {
      return area.current ? {
        name: 'Area Bidder Details',
        params: {
          member_id: this.user.member_id,
          area: area.current.slug
        }
      } : {
        name: 'Bidder Details',
        params: {
          member_id: this.user.member_id
        }
      }
    }
  }
}
</script>
<style>
    .collapsed {
        transition: opacity 1s;
    }
</style>
