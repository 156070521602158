<template>
    <div class="row no-gutters" v-if="bidder">
        <div class="col bg-info px-4 py-2 text-white">
            <div class="row no-gutters">
                <div class="col">
                    <card class="card-plain m-0" card-body-classes="p-0">
                        <span v-if="bidMode || is('admin')">
                            <i class="fas fa-user-lock mr-3"></i>
                            <b>
                                <router-link :to="{ name: 'Area Bidder Details', params: { area: area.slug, member_id: bidder.member_id }}" class="text-white">
                                    {{ bidder.full_name }}
                                </router-link>
                            </b> bidding <b>{{ bidder.round_name }}</b>
                        </span>
                        <span v-else>
                            <i class="fas fa-user-lock mr-3"></i>
                            <b>{{ bidder.full_name }}</b> bidding <b>{{ bidder.round_name }}</b>
                            for <b>{{ bidder.full_name }}</b>.
                        </span>

                        <div class="float-right">
                            <span class="d-none d-lg-inline-block mr-3">
                                Lock time remaining: ( {{ timer }} )
                            </span>

                            <span v-if="bidMode || is('admin')">
                                <skip-button v-if="bidMode" v-bind="{ bidder }" class="mr-2" />

                                <n-button class="bid-button small m-0 mr-2"
                                          v-if="bidMode && bidder.round_type !== 'leave'"
                                          :disabled="!bidReady"
                                          type="success"
                                          round
                                          @click.native="submitBid()">
                                    <i class="fas fa-clipboard-check fa-md mr-2"></i>
                                    Submit Bid
                                </n-button>

                                <n-button round
                                          class="bid-button small m-0"
                                          type="light"
                                          @click.native="cancelBidder">
                                    <el-tooltip content="Remove lock on area by user. Not a SKIP">
                                        <span>
                                            <i class="fas fa-user-times text-white cursor-pointer"></i>
                                            Cancel
                                        </span>
                                    </el-tooltip>
                                </n-button>
                            </span>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<style></style>
<script>
import { mapGetters } from 'vuex'
import SkipButton from '@/components/SkipButton'

export default {
  name: 'active-bidder-bar',
  components: {
    SkipButton
  },
  props: {},
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      is: 'auth/is',
      facility: 'facility/facility',
      area: 'area/area',
      user: 'auth/user',
      bidder: 'bidder/active',
      bidMode: 'bidder/bidMode',
      bidReady: 'bidder/bidReady',
      timer: 'countdown/remaining'
    })
  },
  methods: {
    cancelBidder () {
      this.$store.dispatch('bidder/endActiveBidder', this.bidder)

      // Redirect
      if (this.is('bidaid') && this.$router.name !== 'Round') {
        this.$router.push({ name: 'Round', params: this.$route.params })
      } else {
        this.$router.push({ name: 'Area Home', params: this.$route.params })
      }
    },
    submitBid () {
      this.$confirm('Are you sure you want to submit your bid? <br><strong>This can not be undone!</strong>', 'Line Bid', {
        type: 'info',
        confirmButtonText: 'Submit Bid',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.$store.dispatch('bidder/bid')
          .then(() => {
            // Update the rounds (primarily for leave slot actions, i.e., depleted)
            if (this.is('bidaid')) {
              this.$router.push({ name: 'Round', params: { facility: this.facility.id, area: this.area.slug } })
            } else {
              this.$router.push({ name: 'Area Home', params: { facility: this.facility.id, area: this.area.slug } })
            }
          })
      })
    }
  }
}
</script>
